<template>
  <v-row>
    <!-- Loading State for Fetching Programs -->
    <v-col v-if="isFetching" cols="12">
      <v-alert type="info">
        Hold on a few seconds, your programs are being fetched...
      </v-alert>
      <v-progress-linear
        v-model="fetchProgress"
        color="blue"
        height="10"
        striped
      ></v-progress-linear>
    </v-col>

    <!-- Programs List -->
    <v-col
      v-for="(programGroup, index) in groupedPrograms"
      :key="index"
      cols="12"
      md="6"
    >
      <v-card>
        <v-card-title>
          {{ programGroup[0].activityName }}
        </v-card-title>
        <v-expansion-panels>
          <v-expansion-panel
            v-for="program in programGroup"
            :key="program._id"
          >
            <v-expansion-panel-title>
              <v-row no-gutters>
                <v-col class="d-flex justify-start" cols="4">
                  {{ program.weekday }}
                </v-col>
                <v-col class="text--secondary" cols="8">
                  <v-row>
                    <v-col class="d-flex justify-start" cols="6">
                      Start Time: {{ program.InicialTime || "Not set" }}
                    </v-col>
                    <v-col class="d-flex justify-start" cols="6">
                      End Time: {{ program.FinalTime || "Not set" }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <!-- Content display here -->
              <v-row>
                <v-col cols="6">Evento</v-col>
                <v-col cols="6" class="d-flex justify-end">               
               
                  {{ program.weekday }}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">Horas</v-col>
                <v-col cols="6" class="d-flex justify-end">
            
                 
                  Start Time:
                    {{ program.InicialTime || "Not set" }} FinalTime:
                    {{ program.FinalTime || "Not set" }}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">Convidado</v-col>
                <v-col cols="6" class="d-flex justify-end">
                 
                  {{ program.Convidado }}
                  
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">Redes sociais</v-col>
                <v-col cols="6" class="d-flex justify-end">
                  <v-row class="d-flex justify-end">
                    <v-col>
                      
                      
                      <v-btn
                          :href="program.Facebook"
                          target="_blank"
                          size="small"
                          icon
                          variant="outlined"
                        >
                          <v-icon>mdi-facebook</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col>
                     
                      
                      <v-btn
                          :href="program.Youtube"
                          target="_blank"
                          size="small"
                          icon
                          variant="outlined"
                        >
                          <v-icon>mdi-youtube</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col>
                    
                      
                      <v-btn
                          :href="program.Instagram"
                          target="_blank"
                          size="small"
                          icon
                          variant="outlined"
                        >
                          <v-icon>mdi-instagram</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col>
                     
                      <v-btn
                          :href="program.Spotify"
                          target="_blank"
                          size="small"
                          icon
                          variant="outlined"
                        >
                          <v-icon>mdi-spotify</v-icon>
                        </v-btn>
                     
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
    <v-col cols="6">Cover Image</v-col>
    <v-col cols="6" class="d-flex justify-end">
      <v-img
          v-if="program.coverImage && Array.isArray(program.coverImage) && program.coverImage.length > 0 && program.coverImage[0].path"
          :src="program.coverImage[0].path"
          height="150"
          width="200"
          alt="Cover Image"
        ></v-img>
    
    </v-col>
  </v-row>

              <v-row>
                <v-col cols="12">
                  <div class="quill-class" v-html="renderContent(program.content)"></div>
                </v-col>
              </v-row>
            </v-expansion-panel-text>
            <v-card-actions>
              <v-btn @click="openEditDialog(program)">Edit</v-btn>
              <v-btn @click="deleteProgram(program)" color="red">Delete</v-btn>
            </v-card-actions>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
  </v-row>

  <!-- Edit Dialog -->
  <v-dialog v-model="editDialog" max-width="600">
    <v-card>
      <v-card-title>Edit Program</v-card-title>
      <v-card-text>
        <v-text-field v-model="editedProgram.activityName" label="Discipline"></v-text-field>
        <v-text-field v-model="editedProgram.weekday" label="Event Name"></v-text-field>
        <v-text-field v-model="editedProgram.InicialTime" label="Start Time" type="time"></v-text-field>
        <v-text-field v-model="editedProgram.FinalTime" label="End Time" type="time"></v-text-field>
        <v-text-field v-model="editedProgram.Convidado" label="Convidado"></v-text-field>
        <v-text-field v-model="editedProgram.Facebook" label="Facebook"></v-text-field>
        <v-text-field v-model="editedProgram.Instagram" label="Instagram"></v-text-field>
        <v-text-field v-model="editedProgram.Youtube" label="Youtube"></v-text-field>
        <v-text-field v-model="editedProgram.Spotify" label="Spotify"></v-text-field>
        <v-file-input v-model="editedProgram.coverImageFile" label="Upload Artist Photo"></v-file-input>
        <quill-editor v-model:content="editedProgram.content" contentType="delta" theme="snow" style="height: 200px"></quill-editor>
      </v-card-text>
      <v-card-actions>
        <!-- Loading State for Updating Program -->
        <v-btn @click="saveEditedProgram" :disabled="isUpdating">
          {{ isUpdating ? "Updating..." : "Save" }}
        </v-btn>
        <v-btn @click="editDialog = false">Cancel</v-btn>
      </v-card-actions>
      <v-progress-linear
        v-if="isUpdating"
        v-model="updateProgress"
        color="blue"
        height="10"
        striped
      ></v-progress-linear>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import axios from "axios";
import Quill from "quill";
import Delta from "quill-delta";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

export default {
  components: {
    QuillEditor,
  },
  setup() {
    const programs = ref([]);
    const editDialog = ref(false);
    const editedProgram = ref(null);
    const isFetching = ref(false);
    const fetchProgress = ref(0);
    const isUpdating = ref(false);
    const updateProgress = ref(0);

    const fetchProgramsData = async () => {
      isFetching.value = true;
      fetchProgress.value = 0;
      try {
        const response = await axios.get("https://server.matapa.org/api/programas", {
          onDownloadProgress: (progressEvent) => {
            if (progressEvent.total) {
              fetchProgress.value = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }
          },
        });
        programs.value = response.data.map((program) => ({
          ...program,
          content: program.content ? new Delta(JSON.parse(program.content)) : new Delta(),
        }));
      } catch (error) {
        console.error("Error fetching programs data:", error);
      } finally {
        isFetching.value = false;
      }
    };

    const groupedPrograms = computed(() => {
      return programs.value.reduce((groups, program) => {
        const group = groups.find(
          (group) => group[0].activityName === program.activityName
        );
        if (group) {
          group.push(program);
        } else {
          groups.push([program]);
        }
        return groups;
      }, []);
    });

    const convertDeltaToHtml = (delta) => {
      const quill = new Quill(document.createElement("div"));
      quill.setContents(delta);
      return quill.root.innerHTML;
    };

    const renderContent = (delta) => {
      if (!delta) return "";
      try {
        if (typeof delta === 'string') {
          delta = JSON.parse(delta);
        }
        if (typeof delta === 'object' && !Array.isArray(delta)) {
          return convertDeltaToHtml(new Delta(delta));
        }
        return convertDeltaToHtml(new Delta({ ops: delta }));
      } catch (error) {
        console.error("Error parsing delta content:", error);
        return "";
      }
    };

    const openEditDialog = (program) => {
      editedProgram.value = { ...program, content: new Delta(program.content) };
      editDialog.value = true;
    };

    const saveEditedProgram = async () => {
      isUpdating.value = true;
      updateProgress.value = 0;
      try {
        const formData = new FormData();
        formData.append("activityName", editedProgram.value.activityName);
        formData.append("weekday", editedProgram.value.weekday);
        formData.append("Convidado", editedProgram.value.Convidado);
        formData.append("Facebook", editedProgram.value.Facebook);
        formData.append("Instagram", editedProgram.value.Instagram);
        formData.append("Youtube", editedProgram.value.Youtube);
        formData.append("Spotify", editedProgram.value.Spotify);
        formData.append("InicialTime", editedProgram.value.InicialTime);
        formData.append("FinalTime", editedProgram.value.FinalTime);
        formData.append("content", JSON.stringify(editedProgram.value.content));
        if (editedProgram.value.coverImageFile) {
          formData.append("coverImage", editedProgram.value.coverImageFile);
        } else if (editedProgram.value.coverImage) {
          formData.append("coverImage", JSON.stringify(editedProgram.value.coverImage));
        }

        const response = await axios.put(
          `https://server.matapa.org/api/programas/${editedProgram.value._id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              if (progressEvent.total) {
                updateProgress.value = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              }
            },
          }
        );

        const updatedProgram = response.data;
        const index = programs.value.findIndex(p => p._id === updatedProgram._id);
        if (index !== -1) {
          programs.value[index] = {
            ...updatedProgram,
            content: new Delta(JSON.parse(updatedProgram.content)),
          };
        }

        editDialog.value = false;
        alert("Program updated successfully!");
      } catch (error) {
        console.error("Error updating program:", error);
      } finally {
        isUpdating.value = false;
      }
    };

    const deleteProgram = async (program) => {
      try {
        await axios.delete(
          `https://server.matapa.org/api/programas/${program._id}`
        );
        fetchProgramsData();
      } catch (error) {
        console.error("Error deleting program:", error);
      }
    };

    onMounted(() => {
      fetchProgramsData();
    });

    return {
      programs,
      groupedPrograms,
      renderContent,
      openEditDialog,
      saveEditedProgram,
      deleteProgram,
      editDialog,
      editedProgram,
      isFetching,
      fetchProgress,
      isUpdating,
      updateProgress,
    };
  },
};
</script>

<style scoped>
.quill-class {
  height: auto;
  min-height: 200px;
}
</style>