<template>
    <v-select
      v-model="selectedEvent"
      :items="eventNames"
      label="Select an Event"
      outlined
      dense
      @update:modelValue="emitSelection"
    ></v-select>
  </template>
  
  <script>
  import { ref, onMounted } from "vue";
  import axios from "axios";
  
  export default {
    emits: ["eventSelected"],
    setup(_, { emit }) {
      const events = ref([]);
      const selectedEvent = ref(null);
  
      // Fetch events from API
      const fetchEvents = async () => {
        try {
          const response = await axios.get("https://server.matapa.org/api/festival");
          events.value = response.data; // Assuming the API returns an array
        } catch (error) {
          console.error("Error fetching events:", error);
        }
      };
  
      // Computed property to extract event names
      const eventNames = ref([]);
  
      onMounted(async () => {
        await fetchEvents();
        eventNames.value = events.value.map(event => event.productName);
      });
  
      // Emit the selected event
      const emitSelection = () => {
        emit("eventSelected", selectedEvent.value);
      };
  
      return { selectedEvent, eventNames, emitSelection };
    }
  };
  </script>
  