<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";
import { QuillEditor } from '@vueup/vue-quill';
import Quill from 'quill';
import ProfileForm from '../components/ProfileForm.vue';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

const isLoading = ref(false);
const isUpdating = ref(false);
const isDeleting = ref(false);
const errorMessage = ref("");
const profiles = ref([]);
const filteredProfiles = ref([]);
const searchQuery = ref("");
const isTableView = ref(true);
const selectedProfile = ref(null);
const dialog = ref(false);
const members = ref(false);
const editDialog = ref(false);
const form = ref({
  name: "",
  country: "",
  position: "",
  city: "",
  representation: "",
  committee: "",
  content: "",
  profile: null,
});
const imageFile = ref(null);

onMounted(async () => {
  await fetchProfiles();
});

async function fetchProfiles() {
  isLoading.value = true;
  errorMessage.value = "";
  try {
    const { data } = await axios.get("https://server.matapa.org/api/profiles");
    profiles.value = data.map(profile => ({
      ...profile,
      profile: profile.profile?.length ? profile.profile[0].path : null,
      content: parseContent(profile.content)
    }));
    filteredProfiles.value = profiles.value;
  } catch (error) {
    console.error("Erro ao buscar perfis:", error);
    errorMessage.value = "Falha ao carregar perfis. Tente novamente.";
  } finally {
    isLoading.value = false;
  }
}
function parseContent(content) {
  try {
    if (content && typeof content === "string") {
      const parsedContent = JSON.parse(content);
      return parsedContent.ops ? parsedContent.ops.map(op => op.insert).join("") : content;
    }
  } catch (error) {
    console.error("Erro ao processar conteúdo do perfil:", error);
  }
  return "";
}


const editProfile = (profile) => {
  console.log('Editing profile:', profile);

  selectedProfile.value = profile;

  if (profile.content) {
    console.log('Profile content:', profile.content);

    // If profile content is in Delta format (check if it's an object with ops)
    if (typeof profile.content === 'object' && Array.isArray(profile.content.ops)) {
      // Directly assign Delta format content
      form.value = {
        ...profile,
        content: profile.content, // Assign Delta format
      };
    } else {
      // If profile content is plain text, convert to Delta format
      const delta = new Quill.imports.delta();
      delta.insert(profile.content); // Convert plain text to Delta format
      console.log('Converted plain text to Delta:', delta);

      form.value = {
        ...profile,
        profile: profile.profile || "",
        content: delta, // Assign converted Delta format
      };
    }
  } else {
    console.log('No content found in the profile');
    form.value = {
      ...profile,
      content: new Quill.imports.delta(), // Set empty Delta if no content
    };
  }

  // Open the edit dialog
  editDialog.value = true;
};


async function updateProfile() {
  isUpdating.value = true;
  errorMessage.value = "";
  try {
    const payload = new FormData();
    Object.keys(form.value).forEach((key) => {
      if (key === "content") {
        payload.append(key, JSON.stringify(form.value.content));
      } else if (key === "profile") {
        // Append the new profile image if it exists, otherwise append the existing image data
        if (imageFile.value instanceof File) {
          payload.append("profile", imageFile.value);
        } else if (selectedProfile.value.profile) {
          // Append the existing profile image data
          payload.append("profile", JSON.stringify(selectedProfile.value.profile));
        }
      } else {
        payload.append(key, form.value[key]);
      }
    });

    console.log("Payload being sent:", payload); // Debugging

    const response = await axios.put(
      `https://server.matapa.org/api/profiles/${selectedProfile.value._id}`,
      payload,
      { headers: { "Content-Type": "multipart/form-data" } }
    );

    console.log("Server response:", response.data);
    fetchProfiles();
    editDialog.value = false;
    resetForm();
  } catch (error) {
    console.error("Error updating profile:", error.response ? error.response.data : error);
    errorMessage.value = "Falha ao atualizar perfil. Tente novamente.";
  } finally {
    isUpdating.value = false;
  }
}


const deleteProfile = async (id) => {
  if (!confirm("Tem certeza que deseja excluir este perfil?")) return;
  isDeleting.value = true;
  errorMessage.value = "";
  try {
    await axios.delete(`https://server.matapa.org/api/profiles/${id}`);
    fetchProfiles();
  } catch (error) {
    console.error("Erro ao excluir perfil:", error);
    errorMessage.value = "Falha ao excluir perfil. Tente novamente.";
  } finally {
    isDeleting.value = false;
  }
};

const viewProfile = (profile) => {
  selectedProfile.value = profile;
  dialog.value = true;
};

const formProfile = (member) => {
  selectedProfile.value = member;
  members.value = true;
};

const filterProfiles = () => {
  const query = searchQuery.value.toLowerCase();
  filteredProfiles.value = profiles.value.filter(profile =>
    profile.name.toLowerCase().includes(query) ||
    profile.country.toLowerCase().includes(query) ||
    profile.position.toLowerCase().includes(query)
  );
};

const handleImageUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    imageFile.value = file;
    form.value.profile = URL.createObjectURL(file); // Atualiza a pré-visualização da imagem
  }
};

const resetForm = () => {
  form.value = {
    name: "",
    country: "",
    position: "",
    city: "",
    representation: "",
    committee: "",
    contacto: "",
    website: "",
    content: "",
    profile: null,
  };
  imageFile.value = null;
};
</script>

<template>
  <v-container>
    <v-card>
      <v-card-title>
        Lista de Perfis
        <v-btn icon class="float-right" @click="isTableView = !isTableView">
          <v-icon>{{ isTableView ? "mdi-view-grid" : "mdi-view-list" }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <!-- Loading State -->
        <v-progress-linear v-if="isLoading" indeterminate color="primary"></v-progress-linear>

        <!-- Error Message -->
        <v-alert v-if="errorMessage" type="error" class="mb-4">
          {{ errorMessage }}
        </v-alert>

        <v-sheet class="d-flex">
          <v-sheet class="ma-2 me-auto">
            <v-btn color="primary" @click="formProfile(item)">Adicionar Perfil</v-btn>
          </v-sheet>
          <v-sheet class="ma-2">
            <v-text-field width="340" v-model="searchQuery" label="Pesquisar..." @input="filterProfiles" clearable></v-text-field>
          </v-sheet>
        </v-sheet>

        <!-- Table View -->
        <v-data-table v-if="isTableView" :items="filteredProfiles" :headers="[
          { text: 'Imagem', value: 'profile' },
          { text: 'Nome', value: 'name' },
          { text: 'País', value: 'country' },
          { text: 'Posição', value: 'position' },
          { text: 'Email', value: 'contacto' },
          { text: 'Website', value: 'website' },
          { text: 'Ações', key: 'actions', sortable: false }
        ]">
          <template v-slot:body="{ items }">
            <tr v-for="item in items" :key="item._id">
              <td><v-img v-if="item.profile" :src="item.profile" max-width="50"/></td>
              <td>{{ item.name }}</td>
              <td>{{ item.country }}</td>
              <td>{{ item.position }}</td>
              <td>{{ item.contacto }}</td>
              <td>{{ item.website }}</td>
              <td>
                <v-btn size="small" variant="flat" icon @click="viewProfile(item)"><v-icon>mdi-eye</v-icon></v-btn>
                <v-btn size="small" variant="flat" icon @click="editProfile(item)"><v-icon>mdi-pencil</v-icon></v-btn>
                <v-btn size="small" variant="flat" icon @click="deleteProfile(item._id)" :loading="isDeleting">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>

        <!-- Grid View -->
        <v-row v-else>
          <v-col v-for="item in filteredProfiles" :key="item._id" cols="12" sm="6" md="4">
            <v-card>
              <v-img v-if="item.profile" :src="item.profile" height="150px" cover></v-img>
              <v-card-title>{{ item.name }}</v-card-title>
              <v-card-subtitle>{{ item.country }}</v-card-subtitle>
              <v-card-text>{{ item.position }}</v-card-text>
              <v-card-text>{{ item.contacto }}</v-card-text>
              <v-card-text>{{ item.website }}</v-card-text>
              <v-card-actions>
                <v-btn size="small" variant="flat" @click="viewProfile(item)">Detalhes</v-btn>
                <v-btn size="small" variant="flat" icon @click="editProfile(item)"><v-icon>mdi-pencil</v-icon></v-btn>
                <v-btn size="small" variant="flat" icon color="red" @click="deleteProfile(item._id)" :loading="isDeleting">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- View Profile Dialog -->
    <v-dialog v-model="dialog" max-width="600px">
      <v-card v-if="selectedProfile">
        <v-card-title>{{ selectedProfile.name }}</v-card-title>
        <v-img v-if="selectedProfile.profile" :src="selectedProfile.profile" height="200px" cover></v-img>
        <v-card-text>
          <p><strong>País:</strong> {{ selectedProfile.country }}</p>
          <p><strong>Posição:</strong> {{ selectedProfile.position }}</p>
          <p><strong>Email:</strong> {{ selectedProfile.contacto }}</p>
          <p><strong>Website:</strong> {{ selectedProfile.website }}</p>
          <p><strong>Cidade:</strong> {{ selectedProfile.city }}</p>
          <p><strong>Representação:</strong> {{ selectedProfile.representation }}</p>
          <p><strong>Comitê:</strong> {{ selectedProfile.committee }}</p>
          <p><strong>Conteúdo:</strong> {{ selectedProfile.content }}</p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="dialog = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add Profile Dialog -->
    <v-dialog v-model="members" max-width="600px">
      <ProfileForm />
    </v-dialog>

    <!-- Edit Profile Dialog -->
    <v-dialog v-model="editDialog" max-width="800px">
      <v-card>
        <v-card-title>Editar Perfil</v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field v-model="form.name" label="Nome"></v-text-field>
            <v-text-field v-model="form.country" label="País"></v-text-field>
            <v-text-field v-model="form.position" label="Posição"></v-text-field>
            <v-text-field v-model="form.contacto" label="Email"></v-text-field>
            <v-text-field v-model="form.website" label="Website"></v-text-field>
            <v-text-field v-model="form.city" label="Cidade"></v-text-field>
            <v-text-field v-model="form.representation" label="Representação"></v-text-field>
            <v-text-field v-model="form.committee" label="Comitê"></v-text-field>
            <v-file-input name="profile" label="Imagem de Perfil" @change="handleImageUpload"></v-file-input>
            <v-img
                v-if="form.profile"
                :src="typeof form.profile === 'string' ? form.profile : form.profile[0]?.path"
                alt="Imagem do perfil"
                contain
                max-height="150"
                class="mb-3"
              />
            <QuillEditor v-model:content="form.content" theme="snow" />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="updateProfile" :loading="isUpdating">Salvar</v-btn>
          <v-btn color="secondary" @click="editDialog = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>