<template>
  <v-container>
    <v-form ref="form">
      <div class="d-flex justify-center mb-6">
      <v-sheet
        class="ma-2 pa-2 text-h6"
      >
        Adicionar programa cultural
      </v-sheet>
    </div>
      <div v-for="(program, index) in programs" :key="index">
        <v-row v-if="program.showFields">
          <v-col cols="12">
            <!-- <v-select
              label="Selecione o dia da semana"
              :items="weekday"
              v-model="program.weekday"
              variant="outlined"
            ></v-select> -->
          </v-col>
          <v-col cols="12">
            <EventSelect @eventSelected="handleEventSelection" v-model="program.weekday" />
            <!-- <v-text-field
              label="Nome da atividade"
              v-model="program.activityName"
              variant="outlined"
              required
            ></v-text-field> -->
            <SelectTagseventComponent v-model="program.activityName"/>
          </v-col>
          <v-col cols="6">
            <v-text-field
              type="time"
              label="Hora inicial"
              v-model="program.InicialTime"
              variant="outlined"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              type="time"
              label="Hora final"
              v-model="program.FinalTime"
              variant="outlined"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Convidado"
              v-model="program.Convidado"
              variant="outlined"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Facebook"
              v-model="program.Facebook"
              variant="outlined"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Instagram"
              v-model="program.Instagram"
              variant="outlined"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Youtube"
              v-model="program.Youtube"
              variant="outlined"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Spotify"
              v-model="program.Spotify"
              variant="outlined"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-file-input
              label="Imagem do convidado"
              v-model="program.coverImage"
              variant="outlined"
              @change="previewImage(program, $event)"
            ></v-file-input>
          </v-col>
          <v-col cols="12" v-if="program.previewImageUrl">
            <v-img
              :src="program.previewImageUrl"
              alt="Imagem do convidado"
              height="200px"
            ></v-img>
          </v-col>
          <v-col cols="12" class="pb-10">
            <QuillEditor v-model="editorContent" @ready="onEditorReady" />
          </v-col>
          <v-col cols="12">
            <v-btn
              variant="text"
              size="small"
              @click="deleteProgram(index)"
              color="error"
              text
              icon
            >
              <v-icon>mdi-delete</v-icon>
              <v-tooltip color="primary" activator="parent" location="end">
                Apagar programa
              </v-tooltip>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider v-if="program.showFields"></v-divider>
      </div>
    </v-form>
    <v-row>
      <v-col cols="12">
        <v-btn icon variant="outlined" color="primary" @click="addProgram">
          <v-icon>mdi-plus</v-icon>
         
          <v-tooltip color="primary" activator="parent" location="end">
            Adicionar Novo Programa
              </v-tooltip>
        </v-btn>
        <div class="my-5"></div>
        <v-btn block :loading="loading" color="#1867c0" @click="savePrograms" class="pa-6">
          Salvar Todos
        </v-btn>
      </v-col>
    </v-row>
    <v-snackbar v-model="successMessage" timeout="3000" color="success">
      Programas salvos com sucesso!
    </v-snackbar>
  </v-container>
</template>

<script setup>
import { ref } from "vue";
import axios from "axios";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import SelectTagseventComponent from "./SelectTagseventComponent.vue";
import EventSelect from "./EventSelect.vue";
// const weekday = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];
const programs = ref([]);
const editorContent = ref(null);
let quillInstance = null;
const loading = ref(false);
const successMessage = ref(false);

const previewImage = (program, event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      program.previewImageUrl = e.target.result;
    };
    reader.readAsDataURL(file);
  } else {
    program.previewImageUrl = null;
  }
};

const addProgram = () => {
  programs.value.push({
    activityName: "",
    weekday: "",
    InicialTime: "",
    FinalTime: "",
    Convidado: "",
    Facebook: "",
    Instagram: "",
    Youtube: "",
    Spotify: "",
    coverImage: null,
    previewImageUrl: null,
    showFields: true,
  });
};

const deleteProgram = (index) => {
  programs.value.splice(index, 1);
};

const onEditorReady = (editorInstance) => {
  quillInstance = editorInstance;
};

const savePrograms = async () => {
  try {
    loading.value = true;

    const formattedPrograms = programs.value.map((program) => {
      const formData = new FormData();
      formData.append("weekday", program.weekday);
      formData.append("activityName", program.activityName);
      formData.append("InicialTime", program.InicialTime);
      formData.append("FinalTime", program.FinalTime);
      formData.append("Convidado", program.Convidado);
      formData.append("Facebook", program.Facebook);
      formData.append("Instagram", program.Instagram);
      formData.append("Youtube", program.Youtube);
      formData.append("Spotify", program.Spotify);
      formData.append("content", JSON.stringify(quillInstance?.getContents()));

      if (program.coverImage) {
        formData.append("coverImage", program.coverImage);
      }

      return formData;
    });

    await Promise.all(
      formattedPrograms.map((formData) =>
        axios.post("https://server.matapa.org/api/save-programas", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
      )
    );

    successMessage.value = true;
    resetForm();
  } catch (error) {
    console.error("Erro ao salvar programas:", error);
  } finally {
    loading.value = false;
  }
};

const resetForm = () => {
  programs.value = [];
  editorContent.value = null;
};
</script>
