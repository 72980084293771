<template>
  <h4 class="py-8">Crie tags para eventos</h4>
  <v-form @submit.prevent="addtags">
    <v-row>
      <v-col cols="12">
        <v-text-field
          variant="outlined"
          density="compact"
          v-model="newTags.name"
          label="Nome do grupo de tags"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-row
          class="pl-5"
          v-for="(subtags, subIndex) in newTags.subtags"
          :key="subIndex"
        >
          <v-col cols="10">
            <v-text-field
              hide-details
              variant="outlined"
              density="compact"
              v-model="subtags.name"
              label="Nome da tag"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn
              variant="text"
              size="small"
              @click="removeNewSubtags(subIndex)"
              color="error"
              text
              icon
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="pl-4" cols="6">
        <v-btn
          variant="outlined"
          size="small"
          @click="addNewSubtags"
          color="primary"
          icon
          ><v-icon>mdi-plus</v-icon>
          <v-tooltip color="primary" activator="parent" location="end"
            >Adicionar subregime</v-tooltip
          >
        </v-btn>
      </v-col>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn class="my-4" type="submit" height="45" border="0" color="success"
          >Salvar Curso</v-btn
        >
      </v-col>
    </v-row>
  </v-form>
  <v-expansion-panels v-if="tags.length > 0">
    <v-expansion-panel v-for="(tags, catIndex) in tags" :key="catIndex">
      <v-expansion-panel-title v-model="tags.name">
        <h4>{{ tags.name }}</h4>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-list>
          <v-list-item-group>
            <v-list-item>
              <v-list-item-content>
                <v-text-field
                  variant="solo-filled"
                  v-model="tags.name"
                  label="Curso"
                  :disabled="!tags.editing"
                ></v-text-field>
                <v-row
                  class="pl-5"
                  v-for="(subtags, subIndex) in tags.subtags"
                  :key="subIndex"
                  no-gutters
                >
                  <v-col cols="10">
                    <v-text-field
                      variant="outlined"
                      density="compact"
                      v-model="subtags.name"
                      label="Subregime"
                      :disabled="!tags.editing"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      v-if="tags.editing"
                      variant="text"
                      size="small"
                      @click="deleteSubtags(tags, subIndex)"
                      color="error"
                      text
                      icon
                    >
                      <v-icon>mdi-delete</v-icon>
                      <v-tooltip
                        color="primary"
                        activator="parent"
                        location="end"
                        >Apagar subcurso</v-tooltip
                      >
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="tags.editing">
                  <v-col cols="10">
                    <v-text-field
                      variant="outlined"
                      density="compact"
                      v-model="newSubtags[tags._id]"
                      label="New Subregime Name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      size="small"
                      @click="addSubtags(tags)"
                      color="primary"
                      text
                      icon
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  v-if="!tags.editing"
                  @click="tags.editing = true"
                  color="primary"
                  class="mr-5"
                  ><v-icon>mdi-pencil</v-icon>Edit</v-btn
                >
                <v-btn
                  v-else
                  @click="saveTags(tags)"
                  color="success"
                  class="mr-5"
                  ><v-icon>mdi-floppy</v-icon>Salvar categoria</v-btn
                >
                <v-btn
                  variant="outlined"
                  @click="deleteTags(tags)"
                  color="error"
                  ><v-icon>mdi-delete</v-icon>Apagar</v-btn
                >
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>

  <v-alert v-else>Nenhum Curso disponivel.</v-alert>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";

const tags = ref([]);
const newTags = ref({
  name: "",
  subtags: [{ name: "" }],
});

const newSubtags = ref({});

const fetchTags = async () => {
  try {
    const response = await axios.get("https://server.matapa.org/api/tags");
    tags.value = response.data.map((tag) => ({ ...tag, editing: false }));
  } catch (error) {
    console.error("Error fetching regimes:", error);
  }
};

const addtags = async () => {
  try {
    const response = await axios.post(
      "https://server.matapa.org/api/tags",
      newTags.value
    );
    tags.value.push({ ...response.data, editing: false });
    newTags.value = { name: "", subtags: [{ name: "" }] };
  } catch (error) {
    console.error("Error adding regimes:", error);
  }
};

const addNewSubtags = () => {
  newTags.value.subtags.push({ name: "" });
};

const removeNewSubtags = (subIndex) => {
  newTags.value.subtags.splice(subIndex, 1);
};

const addSubtags = async (tags) => {
  if (!newSubtags.value[tags._id]) return;
  tags.subtags.push({ name: newSubtags.value[tags._id] });
  newSubtags.value[tags._id] = "";
};

const saveTags = async (tags) => {
  try {
    await axios.put(`https://server.matapa.org/api/tags/${tags._id}`, tags);
    tags.editing = false;
  } catch (error) {
    console.error("Error saving curso:", error);
  }
};

const deleteTags = async (id) => {
  try {
    await axios.delete(`https://server.matapa.org/api/tags/${id._id}`); // Pass id._id instead of id
    console.log("Types and subregimes deleted successfully");
    fetchTags(); // Refresh the list after deletion
  } catch (error) {
    console.error("Error deleting regimes and subcategories:", error);
  }
};

const deleteSubtags = (tags, subIndex) => {
  tags.subtags.splice(subIndex, 1);
};

onMounted(fetchTags);
</script>

<style scoped></style>
