<script setup>
import { ref, onMounted, nextTick } from "vue";
import { useRoute } from "vue-router";
import axios from "axios";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

const route = useRoute();
const loading = ref(false);
const successMessage = ref("");
const errorMessage = ref("");

const profile = ref({
  name: "",
  position: "",
  city: "",
  country: "",
  contacto: "",
  website: "",
  representation: "",
  committee: "",
  productExpirationDate: "",
  content: "",
  profile: null,
});

const editorContent = ref("");
const fileInput = ref(null);
const previewImage = ref(null);
const isEditing = ref(false);

onMounted(async () => {
  if (route.params.id) {
    isEditing.value = true;
    const { data } = await axios.get(`https://server.matapa.org/api/profiles/${route.params.id}`);
    
    profile.value = { 
      ...data, 
      productExpirationDate: data.productExpirationDate?.slice(0, 10) 
    };

    await nextTick();
    editorContent.value = data.content ? JSON.parse(data.content) : "";
    
    previewImage.value = data.profile.length ? data.profile[0].path : null;
  }
});

const handleFileChange = (event) => {
  const file = event.target.files[0];
  profile.value.profile = file;
  previewImage.value = URL.createObjectURL(file);
};

const submitForm = async () => {
  loading.value = true;
  successMessage.value = "";
  errorMessage.value = "";

  const formData = new FormData();
  Object.keys(profile.value).forEach((key) => {
    if (key === "content") {
      formData.append(key, JSON.stringify(editorContent.value));
    } else if (key === "profile" && profile.value.profile instanceof File) {
      formData.append("profile", profile.value.profile);
    } else {
      formData.append(key, profile.value[key]);
    }
  });

  try {
    if (isEditing.value) {
      await axios.put(`https://server.matapa.org/api/profiles/${route.params.id}`, formData);
    } else {
      await axios.post("https://server.matapa.org/api/profiles", formData);
    }

    successMessage.value = "Perfil salvo com sucesso!";
    resetForm();
  } catch (error) {
    errorMessage.value = "Erro ao salvar perfil. Tente novamente.";
    console.error("Erro ao salvar perfil:", error);
  } finally {
    loading.value = false;
  }
};

const resetForm = () => {
  profile.value = {
    name: "",
    position: "",
    city: "",
    country: "",
    representation: "",
    committee: "",
    contacto: "",
    website: "",
    productExpirationDate: "",
    content: "",
    profile: null,
  };
  editorContent.value = "";
  previewImage.value = null;
};
</script>

<template>
  <v-container>
    <v-card style="overflow: auto; max-height: 600px;">
      <v-card-title>{{ isEditing ? "Editar Perfil" : "Criar Perfil" }}</v-card-title>
      <v-card-text>
        <v-form @submit.prevent="submitForm">
          <v-text-field v-model="profile.name" label="Nome" required />
          <v-text-field v-model="profile.position" label="Posição" required />
          <v-text-field v-model="profile.city" label="Cidade" />
          <v-text-field v-model="profile.country" label="País" />
          <v-text-field v-model="profile.contacto" label="Email" />
          <v-text-field v-model="profile.website" label="Website" />
          <v-text-field v-model="profile.representation" label="Representação" />
          <v-text-field v-model="profile.committee" label="Comitê" />
          <v-text-field v-model="profile.productExpirationDate" label="Inicio da Activida" type="date" />

          <label>Conteúdo:</label>
          <div style="border: 1px solid #ccc; padding: 10px;">
            <quill-editor v-model:content="editorContent" theme="snow" />
          </div>

          <v-file-input ref="fileInput" label="Upload de Imagem" @change="handleFileChange" />
          <v-img v-if="previewImage" :src="previewImage" max-width="150" class="mt-2" />

          <v-btn :loading="loading" type="submit" color="primary" class="mt-3">
            {{ isEditing ? "Atualizar" : "Salvar" }}
          </v-btn>
        </v-form>

        <v-alert v-if="successMessage" type="success" class="mt-3">{{ successMessage }}</v-alert>
        <v-alert v-if="errorMessage" type="error" class="mt-3">{{ errorMessage }}</v-alert>
      </v-card-text>
    </v-card>
  </v-container>
</template>
