<template>
  <v-autocomplete
    id="subcategory-select"
    v-model="eventName"
    label="Categoria"
    dense
    clearable
    :items="flattenedSubtags.map((subcat) => subcat.name)"
    :item-text="(subcat) => subcat.name"
    :item-value="(subcat) => subcat.id"
    :menu-props="{ group: 'category' }"
  >
  </v-autocomplete>
</template>

<script>
import { ref, onMounted } from "vue";
import axios from "axios";

export default {
  name: "CategorySelector",
  setup() {
    const eventName = ref([]);
    const flattenedSubtags = ref([]);

    const fetchTagsAndSubtags = async () => {
      try {
        const response = await axios.get("https://server.matapa.org/api/tags/all");
        const tags = response.data.tags;

        // Flatten subcategories array to make them selectable
        flattenedSubtags.value = tags.reduce((acc, tag) => {
          return acc.concat(
            tag.subtags.map((sub) => ({
              ...sub,
              tag: tag.name, // Add category property
              disabled: true, // Categories should be disabled
            }))
          );
        }, []);
      } catch (error) {
        console.error("Error fetching tags and subtags:", error);
      }
    };

    // Fetch data when component is mounted
    onMounted(fetchTagsAndSubtags);

    return {
      eventName,
      flattenedSubtags,
    };
  },
};
</script>

<style scoped>
/* Add scoped styles here if needed */
</style>
